<template>
  <div class="h-100">
    <div class="d-flex align-items-center flex-column justify-content-center h-100">
      <div
        id="login-card"
        class="card border-0 shadow"
      >
        <div class="card-body p-5">
          <div class="text-center">
            <h5>Sichern Sie Ihren Account</h5>
            <p class="mb-4">
              Das Einrichten einer 2-Faktor-Authentifizierung
              verleiht Ihrem Account eine weitere Sicherheitsebene.
              Wählen Sie die für Sie passende Methode aus.
            </p>
          </div>
          <div>
            <router-link
              v-if="totpAvailable"
              class="btn btn-gray-7 text-primary text-left mb-3"
              :to="{name: '2faTOTP', query: $route.query}"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-auto">
                    <i class="fad fa-lock-alt fa-2x mt-1" />
                  </div>
                  <div class="col pl-0">
                    <h6 class="mb-1">
                      Authenticator-App
                    </h6>
                    <p class="mb-0">
                      Richten Sie eine Authenticator-App ein um Zwei-Faktor-Authentifizierung zu nutzen.
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div>
            <router-link
              v-if="u2fAvailable"
              class="btn btn-gray-7 text-primary text-left mb-3"
              :to="{name: '2faU2F', query: $route.query}"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-auto">
                    <i class="fad fa-key fa-2x mt-1" />
                  </div>
                  <div class="col pl-0">
                    <h6 class="mb-1">
                      Security-Key
                    </h6>
                    <p class="mb-0">
                      Richten Sie einen Security-Key ein um die Zwei-Faktor-Authentifizierung zu nutzen.
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div>
            <router-link
              v-if="smsAvailable"
              class="btn btn-gray-7 text-primary text-left mb-3"
              :to="{name: '2faSMS', query: $route.query}"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-auto">
                    <i class="fad fa-sms fa-2x mt-1" />
                  </div>
                  <div class="col pl-0">
                    <h6 class="mb-1">
                      SMS
                    </h6>
                    <p class="mb-0">
                      Richten Sie eine Zwei-Faktor-Authentifizierung über SMS ein.
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
            <div class="text-center">
              <button
                class="btn btn-secondary text-primary"
                @click="logout()"
              >
                Abmelden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '2faSetup',
  computed: {
    client () {
      return this.$store.getters['clients/find']().data[0]
    },
    totpAvailable () {
      return this.client && this.client.twoFactor && this.client.twoFactor.TOTP && this.client.twoFactor.TOTP.enabled
    },
    u2fAvailable () {
      return this.client && this.client.twoFactor && this.client.twoFactor.U2F && this.client.twoFactor.U2F.enabled
    },
    smsAvailable () {
      return this.client && this.client.twoFactor && this.client.twoFactor.SMS && this.client.twoFactor.SMS.enabled
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout')
      location.href = '/login'
    }
  }
}
</script>

<style lang="scss" scoped>
#login-card {
  max-width: 500px;
}

</style>
